import React, { useEffect, useState } from 'react';
import './i18n/config';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { Button } from '@mui/material';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import config from './config/config';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import Logo from './components/NavigationBar/Logo';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/hona-sw.js')
    .then((registration) => {
      console.log('SW registered: ', registration);
    })
    .catch((registrationError) => {
      console.log('SW registration failed: ', registrationError);
    });
}

if (config.sentry.dsn_client) {
  Sentry.init({
    dsn: config.sentry.dsn_client,
    beforeSend: async (event) => {
      if (['development', 'dev'].includes(config.env)) {
        return null;
      }
      if (event.request && event.request.url) {
        const allowedDomains = ['localhost', 'hona.com', 'milestones.com'];
        const url = new URL(event.request.url);
        // Ignore requests from unallowed domains
        if (!allowedDomains.includes(url.hostname)) {
          return null;
        }

        // Check if the event has an exception and an error message
        if (
          event.exception &&
          event.exception.values &&
          event.exception.values.length > 0
        ) {
          const errorMessage = event.exception.values[0].value;

          // Ensure the error message exists
          if (errorMessage) {
            const ignoredStatusCodes = [404, 403];
            // Ignore specific status codes if found in the error message
            if (
              ignoredStatusCodes.some((code) =>
                errorMessage.includes(code.toString()),
              )
            ) {
              return null;
            }
          }
        }
      }
      return event;
    },
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: config.env,
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/localhost\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.05, // Record 5% of all sessions
    replaysOnErrorSampleRate: 1.0, // Record 100% of sessions where errors occur
  });
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const reloadPage = () => {
  console.log('reloading page');
  const params = new URLSearchParams(window.location.search);
  params.set('reload', 'true');
  window.location.replace(
    `${window.location.origin}${window.location.pathname}?${params.toString()}`,
  );
};

const reloadListener = () => {
  // check if cache has been invalidated on the company app
  const params = new URLSearchParams(window.location.search);
  const reload = params.get('reload');

  if (!reload) {
    reloadPage();
    return false;
  }
  return true;
};

window.addEventListener('error', reloadListener);

function FallBack() {
  const [error, setError] = useState(false);

  useEffect(() => {
    const hasError = reloadListener();
    setError(!!hasError);
  }, []);

  if (!error) return null;

  return (
    <div
      style={{
        height: '100svh',
        display: 'flex',
        flexDirection: 'column',
        gap: '2em',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <center>
        <Logo />
        <h3 style={{ margin: '2em 0 0.5em 0', color: '#215135' }}>
          Oops! Something went wrong
        </h3>
        <Button
          onClick={reloadPage}
          sx={{
            width: '290px',
            backgroundColor: '#215135',
            color: 'white',
            fontWeight: 'bold',
            fontFamily: 'Poppins, sans-serif',
            ':hover': {
              backgroundColor: '#215135',
            },
          }}
        >
          Refresh
        </Button>
      </center>
    </div>
  );
}

posthog.init(config.posthog.apiKey, {
  api_host: config.posthog.apiHost,
  ui_host: 'https://us.i.posthog.com',
});

root.render(
  <SentryErrorBoundary fallback={<FallBack />}>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </SentryErrorBoundary>,
);

reportWebVitals();
